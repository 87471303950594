html {
  overflow-x: initial !important;
}

/* Flat Theme */

.form-control {
    border-color: #46b8da;
}

.orange {
  background-color: #e2ad66;
}

.btn.flat {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  /*border-width: 1px;*/
  border: none;
  border-width: 0px;
}

.btn.btn-default.flat {
  border-style: solid;
  border-width: 1px;
  border-color: #46b8da;
  color: #46b8da;
}


.form-control.flat {
  border-radius: 0;
  box-shadow: none;
  border-color: #46b8da;
}

.btn.orange.flat:focus,
.btn.orange.flat.focus {
    background-color: #b37522;
}
.btn.orange.flat:hover{
    background-color: #b37522;
}


.text-aqua {
    color: #00c0ef !important;
}

.text-muted {
    color: #777;
}

@media (min-width: 561px) {
  .navbar:not(.stuck) .navbar-header {
      border-bottom: 1px solid #b5b5b5;
  }
   img.site-logo {
    max-width: 76px;
    max-height: 76px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.site-title-block a,
.site-title-block a:hover, 
.site-title-block a:focus {
  text-decoration: none !important;
}

/* Site title on large viewports */
.site-title {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 45px;
}
.site-subtitle {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: 9B9B9B;
  font-size: 14px;
}

/* Site title on small viewports */
@media (max-width: 837px) {
  .site-title {
    font-size: 35px;
  }
  .site-subtitle {
    font-size: 12px;
  }
}

.sponsor-image {
  max-width: 180px;
  max-height: 84px;
}

/*
.navbar-fixed-top .site-title {
  font-size: 25px;
}
.navbar-fixed-top .site-subtitle {
  font-size: 10px;
}
.navbar-fixed-top .sponsor-image {
	height: 30px;
	width: auto;
	margin-top: 5px;
  margin-bottom: 5px;
}
*/

.site-title .left {
  color: #444443;
}
.site-title .right {
  color: #63B6E6;
}

.site-title-block {
  height: 110px;
}
  
.site-title-block
{
  margin-top: auto;
  margin-bottom: auto;
}
img.site-logo
{
   max-width: 76px;
  max-height: 76px;
}

.site-title-block a {
  text-decoration: none;
  cursor: pointer;
}

#main-navbar h1 {
  margin-top: 0;
  margin-bottom: 0;
}

/* Site title on small viewports */
@media (max-width: 838px) {
  .site-title-block {
    height: 105px;
  }
}

@media (max-width: 560px) {
  .site-title-block {
    text-align: center;
    background-color: white;
  }
  img.site-logo {
    max-width: 40px;
    max-height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.navbar-toggle .icon-bar {
  height: 4px;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: black;
}

/* GDPR Requirements */

.cookie-notification {
  background-color: #65b7e7;
  color: black;
}
.cookie-notification .container-fluid {
  padding: 2em;
}
.cookie-notification p {
  margin-bottom: 1em;
}
.cookie-notification a {
  color: #242467;
}

/* Country selector */
.ui-autocomplete {
  padding: 0;
  list-style: none;
  background-color: #fff;
  width: 218px;
  border: 1px solid #B0BECA;
  max-height: 350px;
  overflow-x: hidden;
  /* Override the default z-index to show option lists above modal windows */
  z-index: 10000 !important;
}
.ui-autocomplete .ui-menu-item {
  border-top: 1px solid #B0BECA;
  display: block;
  padding: 0;
  color: #353D44;
  cursor: pointer;
}
.ui-autocomplete .ui-menu-item:first-child {
  border-top: none;
}
.ui-autocomplete .ui-menu-item.ui-state-focus {
  background-color: #D5E5F4;
  color: #161A1C;
}

.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper {
  display: block;
  padding: 4px 6px;
  color: #353D44;
  cursor: pointer;
}
.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: #eeeeee;
  border: none;
}

.dropdown-menu-panel .country-select {
  min-width:300px;
}

.confirmCountryChange .modal-dialog {
  margin-top:50px;
}


.expandable.compact .hidden-expanded-xs {
  opacity:1;
  max-height: 999px; 
}
.expandable.expanded .hidden-expanded-xs {
  opacity:0;
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease .15s;
}
.expandable.expanded .hidden-compact-xs {
  opacity: 1;
  
  -webkit-transition: all 600ms ease,
            opacity 300ms ease;
     -moz-transition: all 600ms ease;
      -ms-transition: all 600ms ease,
              opacity 300ms ease;
       -o-transition: all 600ms ease,
              opacity 300ms ease;
          transition: all 600ms ease,
                opacity 300ms ease;

  -webkit-transform-origin: 0% 0%;
     -moz-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
       -o-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.expandable.compact .hidden-compact-xs {
  opacity:0;
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease .15s;

  -webkit-transform-origin: 0% 100%;
     -moz-transform-origin: 0% 100%;
      -ms-transform-origin: 0% 100%;
       -o-transform-origin: 0% 100%;
          transform-origin: 0% 100%;

  -webkit-transform: rotateX( 80deg );
     -moz-transform: rotateX( 80deg );
      -ms-transform: rotateX( 80deg );
       -o-transform: rotateX( 80deg );
          transform: rotateX( 80deg );
}

.consent-details {
  border:2px solid #dddddd;
  padding:1em;
  /*max-height: 8em;
  overflow-y: auto;*/
}

.hide-overflow {
  overflow: hidden; 
}


/* --- */

.update-consent-tabs li {
  z-index: 10;
}

.update-consent-tabs li.active {
  z-index: 100;
}

.update-consent-tabs li:first-child {
  margin-left: 15px;
}

.update-consent-tabs li:not(:first-child) {
  margin-left: -10px;
}

.country-sites-menu li {
  width: 100%;
  border-bottom: solid 2px #eeeeee;
} 

.country-sites-menu a.side-menu-item {
  color: black;
  font-size: 14px;
}

.country-sites-menu li.active a.side-menu-item {
  font-weight: bold;
}

.update-consent-tabs.nav-tabs {
  /*border-bottom: 2px solid #787878;*/
  border: none;
}

.nav-tabs > li {
  margin-bottom: -2px;
}

.update-consent-tabs.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 2px solid transparent;
  border-radius: 8px 8px 0 0;
  
  color: #ffffff;
  background-color: #787878;
}

.update-consent-tabs.nav-tabs > li.active > a, 
.update-consent-tabs.nav-tabs > li.active > a:hover, 
.update-consent-tabs.nav-tabs > li.active > a:focus {
  color: #ffffff;
  background-color: #63b6e6;
  border: 2px solid #787878;
  border-bottom-color: transparent;
  
}

.update-consent-tabs.nav-tabs > li.active:not(:last-child) > a {
  box-shadow: 6px 0px 6px 0 rgba(0,0,0,0.25);
}

.update-consent-tabs.nav-tabs > li.active:not(:first-child) > a {
  box-shadow: -6px 0px 6px 0 rgba(0,0,0,0.25);
}

.update-consent-tab-content {
  border: 2px solid #787878;
  /*border-top-color: transparent;*/
  margin-bottom: 2em;
  height: 294px;
}

.update-consent-tabs.nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee #787878;
}

.update-consent-tabs.nav > li > a:hover, 
.update-consent-tabs.nav > li > a:focus {
    text-decoration: none;
    background-color: #63b6e6;
}

/* --- */

.no-margin {
  margin: 0;
}

#cancel-subscriptions-modal .modal-dialog {
  margin-top: 40px;
}

.centered-form-group {
  width: 100%;
  max-width: 25em;
  margin-left: auto;
  margin-right: auto;
}

.country-sites-menu {
  background: #fcfeff;
  padding: 0;
}

.country-sites-menu li.active {
  background: #dddddd;
}

.country-sites-menu,
.country-sites-menu-navbar,
.country-sites-menu-container,
.country-sites-tab-content,
.split,
.gutter.gutter-horizontal
{
  height: 290px;
  margin: 0;
}

#updateAllSites {
  padding: 30px 0;
}

.site-tab-pane {
  margin: 15px 35px;
}

.all-sites-tab-pane {
  margin: 15px 0;
}

.country-sites-menu-navbar {
  width: auto;
}

.navbar.country-sites-menu-navbar {
  border: none;
}

.country-sites-menu-navbar .navbar-nav>li>a {
  padding-top: 15px;
  padding-bottom: 15px;
}

.country-sites-menu-navbar ul.nav.navbar-nav {
  width: 300px;
  margin: 0;
}

.gutter {
  background-color: #dde1e2;
  float: right; 
  width: 20px;
  
  background-repeat: no-repeat;
  background-position: 50%;
}

@media (max-width: 560px) {
  .side-menu-item {
    min-height: 40px;
  }
}

@media (max-width: 797px) {
  .country-sites-tab-content {
    padding-left: 20px;
  }
  .country-sites-menu-navbar.closed {
    width: 20px;
  }
  
  .country-sites-menu-navbar:not(.closed) .gutter {
    background-image: url('../images/arrow-left.png');
    border-right: solid 2px #909090;
    border-left: solid 2px #909090;
  }
  .country-sites-menu-navbar.closed .gutter {
    background-image: url('../images/arrow-right.png');
    border-right: solid 2px #909090;
  }
}

@media (min-width: 798px) {

  .update-consent-tab-content {
    height: 264px;
  }
  .country-sites-menu,
  .country-sites-menu-navbar,
  .country-sites-menu-container,
  .country-sites-tab-content,
  .split,
  .gutter.gutter-horizontal
  {
    height: 260px;
  }
  .site-tab-pane {
    margin: 30px 45px;
  }
  .update-consent-tab-content {
    margin-bottom: 2em;
  }
  
  .all-sites-tab-pane {
    margin: 30px 0;
  }

  .country-sites-menu-navbar {
    width: auto;
  }
  .gutter {
    width: 3px;
  }
  .tab-content.country-sites-tab-content {
    width: 100%;
    padding-left: 300px;
  }
}

#confirmCountryChange .modal-body {
  padding-top: 0;
}

#confirmCountryChange form.dirty .updated,
#confirmCountryChange form:not(.updated) .updated {
  display: none;
}
#confirmCountryChange form.updated:not(.dirty) .updated {
  display: block;
}

.form-group.submit-buttons-group {
  margin-bottom: 0;
}

.form-group.submit-buttons-group button {
  margin-bottom: 15px;
}

/* New footer and pre-footer */

.pre-footer {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-left:1px;
}

.pre-footer form {
  padding: 33px 15px 52px 15px;
}

.pre-footer form div {
  display: block;
  vertical-align: middle;
  margin: 19px auto 0 auto;
}

.pre-footer form div label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto 0 auto;
}

.pre-footer form div.validation {
  display: block;
  width: 100%;
  margin: 0 auto 0 auto !important;
}

.pre-footer .sign-up-label {
  color: #000000;
  font-size: 35px;
  margin: 0 15px 0 0;
  padding: 0;
}

.pre-footer form div input {
  height: 35px;
  width: 255px !important;
  margin: 0 0 0 0;
  font-size: 16px;
  color: #cccccc;
}

.pre-footer form div button {
  height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100px;
  margin: 0 0 0 0;
  font-size: 16px;
  color: #ffffff;
}

.bb-social-links-block a {
  text-decoration: none !important;
  margin: 0;
}

.footer-column-header {
  margin-top: 0;
}

.site-footer ul li.text-block {
  line-height: 1.2;
  margin-bottom: 7px;
}

.site-footer .button-column .button-block {
  font-size: 17px;
}

.site-footer .button-column h3 {
  margin-top: 0;
  font-size: 19px;
  font-weight: bold;
  color: black;
}

.site-footer .button-column .btn.btn-info {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: black;
}
 
@media (max-width: 900px) {
  .pre-footer .sign-up-label {
    font-size: 22px;
  }
}

@media (max-width: 730px) {
  .pre-footer {
    display: none;
  }
}

.copyright-row {
  margin-top: 35px;
}


.column-wrapper {
  width: 100%;
  display: table;
}

.bb-footer-col-1 {
  display: table-cell;
  padding-left: 30px;
  padding-right: 30px;
}
.bb-footer-col-2-mobile {
  display: table-cell;
  padding-left: 30px;
  padding-right: 30px;
}
.bb-footer-col-2-desktop {
  display: table-cell;
  padding-left: 30px;
  padding-right: 30px;
}
.bb-footer-col-3 {
  padding-left: 30px;
  padding-right: 30px;
  display: table-cell;
}
.bb-footer-col-4 {
  display: table-cell;
  padding-left: 30px;
  padding-right: 30px;
  width: 165px !important;
}

@media (max-width: 599px) {
  .bb-footer-col-1, .bb-footer-col-2-mobile, .bb-footer-col-2-desktop, .bb-footer-col-3, .bb-footer-col-4 {
    display: block;
    /*width: 70% !important;*/
    width: 400px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .hidden-1-column {
    display: none !important;
  }
  .site-footer .button-column .padding {
    display: none;
  }
}

/* Small mobile: One-column layout */
@media (max-width: 550px) {
  .bb-footer-col-1, .bb-footer-col-2-mobile, .bb-footer-col-2-desktop, .bb-footer-col-3, .bb-footer-col-4 {
    width: 99.9% !important;
    margin-left: 0;
    margin-right: 0;
  }
}

/* Large mobile: Two-column layout with centered button block*/
@media (min-width: 600px) and (max-width: 779px) {
  .column-wrapper {
    display: block;
  }
  
  .bb-footer-col-2-mobile {
    width: 65% !important;
    padding-left: 20%;
    padding-right: 15px;
  }
  
  .hidden-2-column {
    display: none !important;
  }
  
  .site-footer .button-column .padding {
    display: none;
  }
  
  .site-footer .button-column {
    display: block;
    width: 100%;
  }
  
  .button-block {
    width: 275px !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .bb-footer-col-2-desktop {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bb-footer-col-3 {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bb-footer-col-4 {
    width: 35% !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Tablets: Three-column layout */
@media (min-width: 780px) and (max-width: 1019px) {
  .site-footer .button-column .padding {
    margin-bottom: 37px;
  }
  
  .hidden-3-column {
    display: none !important;
  }
  
  .button-block {
    width: 285px !important;
  }
  
  .bb-footer-col-2-mobile {
    width: 285px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bb-footer-col-2-desktop {
    padding-left: 0px;
    padding-right: 15px;
  }
  
  .bb-footer-col-3 {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bb-footer-col-4 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Desktop: Four-column layout */
@media (min-width: 1020px) {
  .site-footer .button-column .padding {
    margin-bottom: 37px;
  }
  
  .hidden-4-column {
    display: none !important;
  }
  
  .bb-footer-col-1 {
    width: 345px !important;
  }
  
  .bb-footer-col-2-mobile {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bb-footer-col-2-desktop {
    padding-left: 0px;
    padding-right: 15px;
    width: 265px;
  }
  
  .bb-footer-col-3 {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .bb-footer-col-4 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bot-trap {
  display: none;
}

.bot-trap-container {
  width: 575px;
  margin: 30px auto 0 auto;
  position: relative;
}

/* More Topics */

.more-topics-panel h3 {
  color: #eb9537;
  margin-left: 8px;
}

.more-topics-panel div.row {
  margin-left: 2px; 
  margin-right: 2px;
}

.more-topics-panel div.row div {
  padding: 4px; 
}

.topic {
  display: inline-block;
  background-color: #5bc0de;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align:left;
}

.topic-more-related {
  display: inline-block;
  color: #5bc0de;
  background-color: white;
  margin: 2px; 
  padding: 4px;
}

.topic-block {
  padding: 4px 10px 4px 10px;
  width: 100%;
  margin: 2px;
}

.selected-keywords .related-topics-btn {
  outline: none !important;
}

.selected-keywords .popover {
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.selected-keywords .popover .popover-content a.topic {
  display: block;
  background-color: white;
  /*color: #606161;*/
  color: #5bc0de;
}
.selected-keywords .popover .popover-content a.topic:hover {
  background-color: #f5f5f5;
  /*color: #5bc0de;*/
  text-decoration: none;
}

/* Awards */

.award-page.awards-banner img {
  width: 94px;
  height: auto;
  float: left;
}

.award-page.awards-banner h2,
.award-page.awards-banner h3 {
  margin: 0 0 0 110px;
}

.award-page.awards-banner h2 {
  margin-top: 12px;
  font-size: 26px;
  font-weight: bold;
}

.award-page.awards-banner h3 {
  font-size: 15px;
  font-weight: bold;
}

.award-page .article-text .date {
  color: orange;
}

.award-page .article-text p {
  font-size: 16px;
}

.award-page .article-text h1 {
  font-weight: bold;
}

.award-page .article-text h2 {
  font-size: 20px;
  font-weight: bold;
  color: gray;
}

.award-page .article-text h3 {
  font-size: 20px;
  font-weight: bold;
}

.award-page .category {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.award-page .category h4 {
  font-weight: bold;    
}

.award-page .category a {
  color: black;
}

.award-page .category a:hover, 
.award-page .category a:focus {
  color: #ebb048;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.award-page .webinar-card-image {
  border: none;
}

.award-page .bb-article-block {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} 

.award-page .bb-article-block.bb-webinar-block {
  padding: 0;
}

.award-page .bb-article-block.bb-webinar-block .bb-article-content {
  border: none;
}

.award-page .blog-title {
  font-style: italic;
}

/* Webinar Series Page Styles*/

.webinar-series-banner h3 {
  font-size: 20px;
}

.webinar-series-banner h2 {
  font-size: 60px;
}

.webinar-list-title h2 {
  font-weight: bold;
  font-size: 30px;
}

.webinar-list-title p {
  font-size: 23px;
}

.bb-webinar-block {
  
}

@media (max-width: 560px) {
  .webinar-series-banner h2 {
    font-size: 30px;
  }
}

@media (min-width: 600px) and (max-width: 779px) {
  .webinar-series-banner h2 {
    font-size: 45px;
  }
}

.webinar-card-image {
  border-top: solid 1px #64b6e6;
  border-left: solid 1px #64b6e6;
  border-right: solid 1px #64b6e6;
  border-bottom: none;
}

.webinar-card-image img {
  width: 100%;
}

.bb-article-block.bb-webinar-block .bb-article-content {
  min-height: 22em;
  margin: 0 0 0 0;
  padding: 15px;
  border: solid;
  border-width: 0 1px 1px 1px;
  border-color: #67b7e4;
}

.webinar-card-title h3 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.webinar-card-speaker {
  color: #67b7e4;
}

.webinar-card-divider {
  border-top: solid #aaaaaa 2px;
  width: 70%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.webinar-card-date {
  
}

.webinar-card-more-info {
  display: block;
  width: 100%;
  padding: 10px 15px;
  
  text-align: center;
  background-color: #67b7e4;
  color: white;
  font-weight: bold;
}

* .grecaptcha-badge {
  margin: auto;
  visibility: hidden;
  display: none;
}

.bb-social-links-block1 {
  line-height: 24px;
  height: 24px;
  margin: 0;
  display: inline;
}
.bb-social-links-block1 a {
  text-decoration: none !important;
  margin: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
}
.bb-social-links-block1 a img {
  height: 24px;
  vertical-align: top;
}
.bb-social-links-block1 .st-custom {
  display: inline-block;
  padding: 8px 12px 8px 0px;
  margin: 0 4px 4px 4px;
  background: #bababa;
  color: white;
  text-decoration: none;
}
.bb-social-links-block1 .st-custom:hover {
  opacity: .75;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.bb-social-links-block1 .st-custom i {
  font-size: 16px;
  font-weight: bold;
  width: 1.5em;
}

@media (min-width: 600px) and (max-width: 779px) {
  .column-wrapper {
    display: flex;
  flex-wrap: wrap;
  }
}
  
@media (min-width: 1020px) {
  .bb-footer-col-1 {
    display: table-cell;
    padding-left: 30px;
    padding-right: 30px;
    width: 25%;
  }
  .bb-footer-col-2-mobile {
    display: table-cell;
    padding-left: 30px;
    padding-right: 30px;
  }
  .bb-footer-col-2-desktop {
    display: table-cell;
    padding-left: 30px;
    padding-right: 30px;
    width: 25%;
  }
  .bb-footer-col-3 {
    padding-left: 30px;
    padding-right: 30px;
    display: table-cell;
    width: 25%;
  }
  .bb-footer-col-4 {
    display: table-cell;
    padding-left: 30px;
    padding-right: 30px;
    width: 25%;
  }
}

.site-footer {
  border-top: 1px solid #A3A3A3;
  padding-top: 20px;
  margin-top: 20px;
}

.bottom-line-min {
  padding-left: 0;
  padding-right: 0;
  background-color: white;
}

.site-header-block .bottom-line-min p {
  margin-bottom: 0em;
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid #A3A3A3;
  border-bottom: 1px solid #A3A3A3;
}

@media (max-width: 200px) {
  .bottom-line-min {
    font-size: 7pt;
  }
  .bottom-line-min span {
    font-size: 7pt;
  }
}

@media screen and (min-width: 201px) and (max-width: 300px) { 
.bottom-line-min {
    font-size: 8pt;
  }
  .bottom-line-min span {
    font-size: 8pt;
  }
}

@media screen and (min-width: 301px) and (max-width: 400px) {
  .bottom-line-min {
    font-size: 9pt;
  }
  .bottom-line-min span {
    font-size: 9pt;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  .bottom-line-min {
    font-size: 10pt;
  }
  .bottom-line-min span {
    font-size: 10pt;
  }
}

@media screen and (min-width: 501px) and (max-width: 560px) {
  .bottom-line-min {
    color: #A3A3A3;
    font-family: Helvetica;
    font-weight: Regular;
    font-size: 12pt;
  }
  .bottom-line-min span {
    color: #535352;
    font-family: Helvetica;
    font-weight: Regular;
    width: 8em;
    font-size: 12pt;
  }
}

#main-div {
  padding-left: 15px;
  padding-right: 15px;
}

#topics-menu-id {
  border-bottom: 1px solid #A3A3A3;
  padding-right: 0;
  padding-left: 0;
}

.sticky-div .navbar-right {
  padding-right: 0;
  padding-left: 0;
}

#main-navbar .navbar-right {
  padding-right: 0;
}

.stuck {
    position:fixed;
    top:0;
    z-index: 999;
    background:white;
    /*
    margin-left: -16px;
    margin-right: 16px;
    */
    padding-left:0rem;
    z-index: 1030;
    border-radius: 0;
    text-align: center;
    display:block;
    width: 100vw;
}

.site-header-block .stuck {
  text-align:center;
}

.stuck .inputbox { 
  padding-right:15px; 
}

.stuck:after {
  content: "";
  position: absolute;
  /* fill vertically */
  top: 0;
  bottom: 0;
  width: 9600px;
  left: 100%;
  background: white;
  border-bottom: 1px solid #A3A3A3;
}

.stuck:before {
  content: "";
  position: absolute;
  /* fill vertically */
  top: 0;
  bottom: 0;
  width: 9600px;
  right: 100%;
  background: white;
  border-bottom: 1px solid #A3A3A3;
}

.stuck .navbar-collapse
{
  padding-left: 0;
  padding-right: 0;
  max-height: 340px;
}

@media (min-width: 561px) {
 .stuck .visible-fixed-top-md {
    display: table-cell !important;
  }
 }
 
.btn-black {
  background-color: #000 !important;
  border-color:  #000 !important;
  color: white;
}
.btn.btn-black:hover, .btn.btn-black:focus, .btn.btn-black.focus {
  background-color: #000 !important;
  color: #aaa;
}
.btn.btn-black:focus, 
.btn.btn-black:active:focus, 
.btn.btn-black.active:focus, 
.btn.btn-black.focus, 
.btn.btn-black:active.focus, 
.btn.btn-black.active.focus {
  outline-offset: 0;
}

.visible-only-fixed-top-md {
  display: none !important;
}
  
@media (min-width: 561px) {
  .navbar.navbar-fixed-top .hidden-fixed-top-md {
    display: none !important;
  }
  .stuck .hidden-fixed-top-md {
    display: none !important;
  }
  
  .navbar.stuck .hidden-fixed-top-md {
    display: none !important;
  }
  .navbar.navbar-fixed-top .visible-fixed-top-md {
    /*display: block !important;*/
    display: table-cell !important;
  }
    
  :not(.stuck) .visible-fixed-top-md {
    display: none !important; /* Not good to use a visible utility class to hide items*/
  }
  
  .stuck .visible-only-fixed-top-md {
    display: table-cell !important;
  }
  
  .navbar-nav > li.site-logo > a {
      padding-top: 5px;
      padding-bottom: 5px;
  }
}

@media (max-width: 560px) {
  .navbar.navbar-fixed-top .visible-fixed-top-xs{
    display: table-cell !important;
  }
.stuck .visible-fixed-top-xs{
    display: table-cell !important;
  }  
  /*
  .navbar:not(.navbar-fixed-top) .visible-fixed-top-xs {
    display: none !important;
  }
  */
}

.gutter-narrow-navbar {
  /*margin-right: auto;
  margin-left: auto;*/
  width: 100%;
}


/***************************/

.site-header-block {
  margin: 0 -15px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

hr1 {
  border: 1;
  clear:both;
  display:block;
  width: 100%;
  background-color:#A3A3A3;
}

.site-header-block_new {
  border: 0;
  clear:both;
  display:block;
  width: 100%;
}
  
.sticky-wrapper {
  width:100%;
  
}
  
@media screen and (min-width: 561px) and (max-width: 859px) {
 .bottom-line-max {
   color: #A3A3A3;
   font-family: Helvetica;
   font-weight: Regular;
   font-size: 12pt;
   margin: auto;
 }
}
@media screen and (min-width: 860px) and (max-width: 970px) {
 .bottom-line-max {
   color: #A3A3A3;
   font-family: Helvetica;
   font-weight: Regular;
   font-size: 12pt;
   margin: auto;
 }  
}

/***************************/

.site-title_new {
  font-family: 'Montserrat';
  font-weight: bold;
}

.site-subtitle_new {
  font-weight: normal;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #545453;
  margin-bottom: 0;
}

@media screen and (min-width: 960px) {
  ul.dotsbetween > li:nth-child(n+11) {
    display: none;
    visibility:hidden;
  }
  
  .site-title_new {
    font-size: 48px;
  }
  
  .site-subtitle_new {
    font-size: 22px;
  }
  
  .brought-by {
    font-size: 16px;
  }
}

@media screen and (min-width: 642px) and (max-width: 959px) {
  ul.dotsbetween > li:nth-child(n+8){
    display: none;
    visibility:hidden;
  }
  .site-title_new {
    font-size: 38px;
  }
  img.site-logo {
    height:50px;
  }
  .site-subtitle_new {
    font-size: 17px;
  }
  .brought-by {
    font-size: 12px;
  }
  p.brought-by-hidden-mx {
    font-size: 13px;
  }
}
  
@media screen and (min-width: 561px) and (max-width: 641px) {
  ul.dotsbetween > li:nth-child(n+5){
    display: none;
    visibility:hidden;
  }
  .site-subtitle_new {
    font-size: 12px;
  }
  .site-title_new {
    font-size: 25px;
  }
  img.site-logo {
    height:40px;
  }
  .brought-by {
    font-size: 8px;
    
  }
  p.brought-by-hidden-mx {
    font-size: 9px;
  }
}

@media screen and (max-width: 560px) {
  ul.dotsbetween > li{
    display: none;
    visibility:hidden;
  }
  .site-title_new {
    font-size: 20px;
  }
  .mobile-menu-button {
    padding-top:2px;
  }
  .site-subtitle_new {
    display: none !important;
  }
}

/***************************/

.countdown-banner-parent {
  align-items: center;
  border: 0px solid transparent;
  display: flex;
  justify-content: center;
}

@media (min-width: 838px) 
{
 .countdown-banner {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #21aedf;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #ffffff;
    margin-bottom:15px;
 }
}

.oneline {
   height: 100px;
   display: inline-block;
   text-align: center;
}

.oneline p {
  margin: 0 0 0;
  display: unset;
}

.center-block {
  float:none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.inner-text {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.text-style {
  text-decoration: none;
  padding: 0px 10px;
  display: block;
}

.award-banner-text {
  color: #ffffff;
  padding: 0 !important;
  font-size: 24px;
}
.oneline .countdown-banner-text {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
}
.oneline div[id^="Container"] div[id^="TextBox"] span {
  font-size: 28px;
  position:relative;
  top: -17px;
  color:#ffffff;
}
.oneline div[id^="TextBox"] span {
  font-size: 11px;
  position:relative;
  top: -25px;
  color:#ffffff;  
}

@media (max-width: 938px) {
  .award-banner-text {
    font-size: 20px;
  }
  .oneline .countdown-banner-text {
    font-size: 15px;
  }
  .oneline div[id^="Container"] div[id^="TextBox"] span {
    font-size: 15px;
  } 
  .oneline div[id^="TextBox"] span {
    font-size: 14px;
  }
  .oneline .award-bttn-text {
    font-size: 14px;
  }
}

/***************************/

.site-footer .bb-social-links-block img {
  height: 30px;
  width: 30px;
}

.texted-line {
  display: table;
  overflow: hidden;
}

.h2-underline {
  border-bottom: 1px solid #5D5D5D;
  margin-top: 10px;
  margin-bottom: 15px;
  max-width: 460px;
}

.sticky.sticky-div.navbar-default.gutter-narrow-navbar.stuck {
  left: 0;
  padding-left: 15px;
  border-bottom: 1px solid #A3A3A3;
}

.sticky.sticky-div.navbar-default.gutter-narrow-navbar.stuck #topics-menu-id {
  border: none;
}

/* Migrated from /editions/new-browser.less */
.center {
  text-align: center;
}

.sign-me-up {
    font-size: 10pt!important;
    color: #939597
}

.form-group {
    padding: 1em 1em .5em 1em;
    margin-bottom: 1em;
}

.site-footer li {
  padding-top: 10px;
}