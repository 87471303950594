/* Select2 Styling */

body {
overflow: scroll;
}

.select2-dropdown.select2-dropdown--below,
.select2-dropdown.select2-dropdown--above {
    min-width: 10em !important;
}

.select2-container--default .select2-selection--single {
    padding:3px;
    height: 34px;
    
    min-width: 10em; 
    //font-size: 1.2em;  
    position: relative;
    
    border-radius: 0;
    
    // Supporting pages styling by default (white fields on white background, gray border) 
    background-color: white; 
    border: 1px solid #d2d6de; // #46b8da?
}

// Front page modal dialog styling (gray fields on white background)
.modal .select2-container--default .select2-selection--single {
    border: none;
    background-color: #eeeeee;
}

// White paper downloading form styling (white fields on gray background)
.whitepaper-registration .select2-container--default .select2-selection--single {
    border: none;
    height: 46px;
    padding-top: 9px;
}
.whitepaper-registration .select2-container {
    margin-bottom: 16px;
}
.whitepaper-registration .invalid + .select2-container--default .select2-selection--single {
    background-color: #FBE3E4;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 36px;
}

.select2-container.required .select2-selection--single .select2-selection__rendered {
    padding-right: 36px;
    
   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc3MicgaGVpZ2h0PSc2OSc+PHBhdGggZmlsbD0nI2EwYTBhMCcgZD0nbTQ0LjI5MzAwMzA4MjI3NTM5IDBsLTMuMDM2OTk4OTg3MTk3ODc2IDI4LjI5Mzk5ODcxODI2MTcyIDI4LjUxNjk5ODI5MTAxNTYyNS03Ljk5OTAwMTAyNjE1MzU2NDUgMi4wNzQwMDUxMjY5NTMxMjUgMTYuMDczMDAxODYxNTcyMjY2IC0yNi42NjUwMDQ3MzAyMjQ2MSAyLjE0Nzk5OTA0ODIzMzAzMjIgMTcuNDc5OTk5NTQyMjM2MzI4IDIzLjAzNTAwMzY2MjEwOTM3NSAtMTQuNzM5OTk3ODYzNzY5NTMxIDcuOTI0OTk0OTQ1NTI2MTIzIC0xMi4yOTUwMDE5ODM2NDI1NzgtMjUuMDM0OTk5ODQ3NDEyMTEgLTExLjAzNjk5ODc0ODc3OTI5NyAyNS4wMzQ5OTk4NDc0MTIxMSAtMTUuMTgzOTk4MTA3OTEwMTU2LTcuOTI0OTk0OTQ1NTI2MTIzIDE3LjEwOTk5Njc5NTY1NDI5Ny0yMy4wMzUwMDM2NjIxMDkzNzUgLTI2LjUxNjAwMjY1NTAyOTI5Ny0yLjE0Nzk5OTA0ODIzMzAzMjIgMi41OTIwMDI4Njg2NTIzNDM4LTE2LjA3MzAwMTg2MTU3MjI2NiAyNy44NTAwMDIyODg4MTgzNiA3Ljk5OTAwMTAyNjE1MzU2NDUgLTMuMDM3MDAzMDQwMzEzNzIwNy0yOC4yOTM5OTg3MTgyNjE3MmgxNi44ODgwMDA0ODgyODEyNWwwIDAgMCAweicvPjwvc3ZnPg==");
   background-size: 7px;
   background-repeat: no-repeat;
   background-position: right 36px top 4px;
}

.select2.select2-container.required.select2-container--disabled .select2-selection--single .select2-selection__rendered {
   background: none !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 34px;
    height: 100%;
    padding: 4px 14px;
    
    background-color: #46b8da;
    color: #fff;
    //font-size: 1.3em;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    width: 0;
    height: 0;
    
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -4px;

    border-color: white transparent transparent transparent;
    border-style: solid;
    border-width: 10px 10px 0 10px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent white transparent;
    border-width: 0 10px 10px 10px;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
    border-radius: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    //color: #999;
    display: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #46b8da;
    color: #fff;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    cursor: default;
    background-color: white;
    opacity: 1;
    border: none;
    -webkit-box-shadow: none;
    font-weight: bold;
    color: #777;
}
.modal .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eeeeee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__arrow {
    display: none;
}
